import { useEffect } from "react";
import { navigate } from "gatsby-plugin-react-intl";

// Redirect to front page
const NotFoundPageDevelopment = () => {
  useEffect(() => {
    navigate("/");
  }, []);
  return null;
};

export default NotFoundPageDevelopment;
